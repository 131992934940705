import API from "../../api/index";

export default {
  getMenu: params => {
    return API.requestGet({
      params,
      url: "son/getRoleBySonId"
    });
  },
  //广告
  getAdvertise: params => {
    return API.requestPost({url: "api/v2/basic/queryBasic", data: {keys: 'sys_advertising'} }, false);
  },
  //是否有新的更新记录
  getNewRecord: params => {
      return API.requestGet({
        params,
        url: "api/v2/user/selectuserversion"
      },false);
    },
  //客服联系方式
  getContact: params => {
    return API.requestPost({
      url: "user/superInfo",
      data:params,
    });
  },
};
